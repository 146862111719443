import React, { FC, useEffect, useState } from "react";
import { useCheckoutIntent } from "../../../hooks/useCheckoutIntent";
import { useThirdPartyCookieCheck } from "../../../hooks/useThirdPartyCookieCheck";
import {
  useNavigationContext,
  useTransactionContext,
} from "../../../providers";
import { useParamContext } from "../../../providers/ParamContextProvider/ParamContextProvider";
import ContainerView from "../ContainerView";
import ErrorView from "../ErrorView";
import LoadingCheckoutView from "../LoadingCheckoutView";
import ExternalView from "./ExternalView";
import IframeView from "./IframeView";
import { useLanguage } from "../../../hooks/useLanguage";
import { APIError, ERROR_CODES } from "../../../utils/errors";

const Steps: FC = () => {
  const {formatMessage} = useLanguage();
  const {params} = useParamContext();
  const {transaction} = useTransactionContext();
  const {
    selectedOnramp,
    selectedFiat,
    selectedCrypto,
    fiatAmount,
    cryptoAmount,
    transactionType,
  } = transaction;
  const {is3PCBlockedOnramp} = useThirdPartyCookieCheck();
  const {skipTransactionScreen, txnRedirect, redirectAtCheckout} = params;
  const [transactionInformation, setTransactionInformation] = useState({
    type: undefined,
    url: undefined,
    params: {permissions: undefined},
  });
  const {nextScreen, currentScreenKey, onlyScreen, firstScreen} =
    useNavigationContext();
  const {url, type, params: widgetParams} = transactionInformation;
  const {
    checkoutIntentResponse,
    isLoading,
    isError,
    mutate: checkout,
    error,
  } = useCheckoutIntent();

  useEffect(() => {
    if (checkoutIntentResponse?.message) {
      setTransactionInformation(
        checkoutIntentResponse?.message.transactionInformation,
      );
    }
  }, [checkoutIntentResponse]);

  useEffect(() => {
    checkout();
  }, [checkout]);

  useEffect(() => {
    if (!isError && !isLoading) {
      const thirdPartyCookiesAvailable = !is3PCBlockedOnramp(
        transaction.selectedOnramp?.id ?? ""
      );

      if (txnRedirect && skipTransactionScreen) {
        window.open(url, "_parent");
      } else if (type === "iframe" && thirdPartyCookiesAvailable) {
        if (currentScreenKey() !== "IframeView") {
          if (currentScreenKey() === "Steps") {
            firstScreen();
          }

          nextScreen(
            <IframeView
              key="IframeView"
              type="onramp"
              url={url}
              params={widgetParams}
              onClose={
                skipTransactionScreen === true
                  ? () => onlyScreen(<ContainerView selectedTabId={0} />)
                  : undefined
              }
            />
          );
        }
      } else if (
        transactionInformation.url &&
        (!thirdPartyCookiesAvailable || type === "popup")
      ) {
        if (currentScreenKey() !== "ExternalView") {
          if (currentScreenKey() === "Steps") {
            firstScreen();
          }
          if (redirectAtCheckout === true) {
            window.open(url, "_parent");
          } else {
            nextScreen(
              <ExternalView
                type="tab"
                onramp={transaction.selectedOnramp}
                key="ExternalView"
                url={url}
                onClose={
                  skipTransactionScreen === true
                    ? () => onlyScreen(<ContainerView selectedTabId={0} />)
                    : undefined
                }
            />
            );
          }
        }
      }
    } else if (isError) {
      if (currentScreenKey() === "Steps") {
        firstScreen();
      }

      if (
        (error as Error).message.trim() ===
        "Demo Key can not be used for checkout"
      ) {
        nextScreen(
          <ErrorView
            errorType="DemoKey"
            key="ErrorView"
            title={formatMessage("steps.error.demoKey.title")}
            description={formatMessage("steps.error.demoKey.description")}
          />
        );
      } else if (
        (error as APIError).errorId === ERROR_CODES.SIGNATURE_VALIDATION_ERROR
      ) {
        nextScreen(
          <ErrorView
            errorType="Checkout"
            key="ErrorView"
            title={formatMessage("steps.error.invalidSign.title")}
            description={formatMessage("steps.error.invalidSign.description")}
          />
        );
      } else {
        nextScreen(
          <ErrorView
            errorType="Checkout"
            key="ErrorView"
            title={formatMessage("steps.error.default.title", {
              provider: selectedOnramp?.id,
            })}
            description={formatMessage("steps.error.default.description")}
          />
        );
      }
    }
  }, [
    formatMessage,
    firstScreen,
    currentScreenKey,
    is3PCBlockedOnramp,
    isError,
    isLoading,
    nextScreen,
    onlyScreen,
    params.apiKey,
    skipTransactionScreen,
    selectedOnramp,
    transactionInformation.url,
    type,
    url,
    widgetParams,
    transaction.selectedOnramp,
    error,
  ]);

  return (
    <LoadingCheckoutView
      key="LoadingView"
      onramp={selectedOnramp}
      source={
        transactionType === "sell" ? selectedCrypto?.code : selectedFiat?.code
      }
      destination={
        transactionType === "sell" ? selectedFiat?.code : selectedCrypto?.code
      }
      sourceAmount={transactionType === 'sell' ? cryptoAmount : fiatAmount}
      destinationAmount={transactionType === 'sell' ? fiatAmount : cryptoAmount}
    />
  );
};

export default Steps;
