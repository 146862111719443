import React, { FC, useCallback, useEffect } from 'react';
import { ReactComponent as Info } from '../../../../icons/shield-tick.svg';
import { useNavigationContext } from '../../../../providers';
import { Onramp } from '../../../../types';
import BottomOverlay from '../../BottomOverlay/BottomOverlay';
import styles from './ExternalView.module.css';
import PendingView from './PendingImage';
import { openWindow } from './utils';
import { ReactComponent as RedirectIcon } from '../../../../icons/redirect.svg';
import { useLanguage } from '../../../../hooks/useLanguage';
import { Button } from '@onramper/oui';

type ExternalViewProps = {
  type: 'popup' | 'tab';
  url?: string;
  onramp: Onramp | null;
  onClose?: () => void;
};

const ExternalView: FC<ExternalViewProps> = ({ url, onramp, type, onClose }: ExternalViewProps) => {
  const { formatMessage } = useLanguage();
  const { backScreen, currentScreenKey } = useNavigationContext();
  if (currentScreenKey() === 'LoadingView') {
    backScreen();
  }

  const openExternalView = useCallback(() => {
    if (url) {
      openWindow(url, type);
    }
  }, [type, url]);

  useEffect(() => {
    openExternalView();
  }, [openExternalView]);

  return (
    <BottomOverlay title={formatMessage('externalView.title')} maxHeight="95%" onClose={onClose}>
      <div className={styles['external-view-container']}>
        <div className={styles['secure-message']}>
          <Info stroke="var(--primary-text-color)" />
          <p>{formatMessage('externalView.secureConnection')}</p>
        </div>
        <div className={styles['external-view-image-container']}>
          <PendingView iconSource={onramp?.icon ?? ''} />
        </div>
        <div className={styles['details-container']}>
          <p className={styles['external-view-title']}>
            {formatMessage('externalView.message.title', {
              provider: onramp?.name,
              type,
            })}
          </p>

          <p className={styles['external-view-description']}>
            {type === 'popup' && formatMessage('externalView.message.description.popup')}
            {type === 'tab' && formatMessage('externalView.message.description.tab')}
          </p>
        </div>
        <div className={styles['external-view-link-container']}>
          <a href={url} target={'_blank'} className={styles['external-view-link']} rel="noopener">
            <Button
              label={formatMessage('externalView.button.label', {
                provider: onramp?.name,
                type,
              })}
              size="large-rectangle"
              expanded
              rightIcon={<RedirectIcon stroke="var(--oui-btn-primary-color)" />}
            />
          </a>
        </div>
      </div>
    </BottomOverlay>
  );
};

export default ExternalView;
